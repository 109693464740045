/* eslint-disable */

/*
 * ⚠️ DO NOT EDIT ⚠️
 * This file is automatically generated, run "yarn run codegen"
 */

import { Types } from '@scorenco/core';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetChHomePageQueryVariables = Types.Exact<{
  publicationState: Types.PublicationState;
  locale: Types.Scalars['I18NLocaleCode']['input'];
}>;

export type GetChHomePageQuery = {
  __typename: 'query_root';
  pagesChHome?:
    | {
        __typename: 'PagesChHomeEntityResponse';
        data?:
          | {
              __typename: 'PagesChHomeEntity';
              id: string;
              attributes?:
                | {
                    __typename: 'PagesChHome';
                    title?: string | undefined;
                    subtitle?: string | undefined;
                    ctaLabel?: string | undefined;
                    locale?: string | undefined;
                    body?:
                      | Array<
                          | {
                              __typename: 'ComponentSlicesAdvertising';
                              id: string;
                              ComponentSlicesAdvertising_advertising_id?:
                                | string
                                | undefined;
                            }
                          | {
                              __typename: 'ComponentSlicesFaq';
                              id: string;
                              title?: string | undefined;
                              content?: string | undefined;
                            }
                          | {
                              __typename: 'ComponentSlicesFeaturesList';
                              id: string;
                              ComponentSlicesFeaturesList_features?:
                                | Array<
                                    | {
                                        __typename: 'ComponentFeatureListFeature';
                                        id: string;
                                        name?: string | undefined;
                                        content?: string | undefined;
                                        ctaLink?: string | undefined;
                                        ctaText?: string | undefined;
                                        icon?:
                                          | {
                                              __typename: 'UploadFileEntityResponse';
                                              data?:
                                                | {
                                                    __typename: 'UploadFileEntity';
                                                    id: string;
                                                    attributes?:
                                                      | {
                                                          __typename: 'UploadFile';
                                                          url?:
                                                            | string
                                                            | undefined;
                                                        }
                                                      | undefined;
                                                  }
                                                | undefined;
                                            }
                                          | undefined;
                                      }
                                    | undefined
                                  >
                                | undefined;
                            }
                          | {
                              __typename: 'ComponentSlicesImage';
                              id: string;
                              ComponentSlicesImage_image?:
                                | {
                                    __typename: 'UploadFileEntityResponse';
                                    data?:
                                      | {
                                          __typename: 'UploadFileEntity';
                                          id: string;
                                          attributes?:
                                            | {
                                                __typename: 'UploadFile';
                                                url?: string | undefined;
                                                alternativeText?:
                                                  | string
                                                  | undefined;
                                                width?: number | undefined;
                                                height?: number | undefined;
                                              }
                                            | undefined;
                                        }
                                      | undefined;
                                  }
                                | undefined;
                            }
                          | {
                              __typename: 'ComponentSlicesImageWithText';
                              id: string;
                              ComponentSlicesImageWithText_text?:
                                | string
                                | undefined;
                              ComponentSlicesImageWithText_imagePosition?:
                                | Types.Enum_Componentslicesimagewithtext_Imageposition
                                | undefined;
                              ComponentSlicesImageWithText_imageSize?:
                                | Types.Enum_Componentslicesimagewithtext_Imagesize
                                | undefined;
                              ComponentSlicesImageWithText_image?:
                                | {
                                    __typename: 'UploadFileEntityResponse';
                                    data?:
                                      | {
                                          __typename: 'UploadFileEntity';
                                          id: string;
                                          attributes?:
                                            | {
                                                __typename: 'UploadFile';
                                                url?: string | undefined;
                                                alternativeText?:
                                                  | string
                                                  | undefined;
                                                width?: number | undefined;
                                                height?: number | undefined;
                                              }
                                            | undefined;
                                        }
                                      | undefined;
                                  }
                                | undefined;
                            }
                          | {
                              __typename: 'ComponentSlicesLinkList';
                              id: string;
                              ComponentSlicesLinkList_color?:
                                | boolean
                                | undefined;
                              ComponentSlicesLinkList_liens?:
                                | Array<
                                    | {
                                        __typename: 'ComponentLinkListLink';
                                        id: string;
                                        title?: string | undefined;
                                        link?: string | undefined;
                                        logo?:
                                          | {
                                              __typename: 'UploadFileEntityResponse';
                                              data?:
                                                | {
                                                    __typename: 'UploadFileEntity';
                                                    id: string;
                                                    attributes?:
                                                      | {
                                                          __typename: 'UploadFile';
                                                          url?:
                                                            | string
                                                            | undefined;
                                                        }
                                                      | undefined;
                                                  }
                                                | undefined;
                                            }
                                          | undefined;
                                      }
                                    | undefined
                                  >
                                | undefined;
                            }
                          | {
                              __typename: 'ComponentSlicesLivesSlider';
                              id: string;
                              ComponentSlicesLivesSlider_limit?:
                                | number
                                | undefined;
                              ComponentSlicesLivesSlider_sportSlug?:
                                | string
                                | undefined;
                            }
                          | {
                              __typename: 'ComponentSlicesNewsletter';
                              id: string;
                              ComponentSlicesNewsletter_title?:
                                | string
                                | undefined;
                              ComponentSlicesNewsletter_buttonText?:
                                | string
                                | undefined;
                              ComponentSlicesNewsletter_signedText?:
                                | string
                                | undefined;
                              ComponentSlicesNewsletter_image?:
                                | {
                                    __typename: 'UploadFileEntityResponse';
                                    data?:
                                      | {
                                          __typename: 'UploadFileEntity';
                                          id: string;
                                          attributes?:
                                            | {
                                                __typename: 'UploadFile';
                                                url?: string | undefined;
                                              }
                                            | undefined;
                                        }
                                      | undefined;
                                  }
                                | undefined;
                            }
                          | {
                              __typename: 'ComponentSlicesSection';
                              id: string;
                              ComponentSlicesSection_theme?:
                                | Types.Enum_Componentslicessection_Theme
                                | undefined;
                            }
                          | {
                              __typename: 'ComponentSlicesSportList';
                              id: string;
                              text?: string | undefined;
                              sports?: string | undefined;
                            }
                          | {
                              __typename: 'ComponentSlicesStoreGrade';
                              id: string;
                              androidDescription?: string | undefined;
                              androidLink?: string | undefined;
                              androidNote?: string | undefined;
                              iosDescription?: string | undefined;
                              iosLink?: string | undefined;
                              iosNote?: string | undefined;
                              ctaLabel?: string | undefined;
                            }
                          | { __typename: 'ComponentSlicesTabEnd'; id: string }
                          | {
                              __typename: 'ComponentSlicesTabStart';
                              id: string;
                              ComponentSlicesTabStart_title?:
                                | string
                                | undefined;
                            }
                          | {
                              __typename: 'ComponentSlicesTestimonial';
                              id: string;
                              ComponentSlicesTestimonial_name?:
                                | string
                                | undefined;
                              ComponentSlicesTestimonial_role?:
                                | string
                                | undefined;
                              ComponentSlicesTestimonial_review?:
                                | string
                                | undefined;
                              ComponentSlicesTestimonial_image?:
                                | {
                                    __typename: 'UploadFileEntityResponse';
                                    data?:
                                      | {
                                          __typename: 'UploadFileEntity';
                                          id: string;
                                          attributes?:
                                            | {
                                                __typename: 'UploadFile';
                                                url?: string | undefined;
                                                alternativeText?:
                                                  | string
                                                  | undefined;
                                                width?: number | undefined;
                                                height?: number | undefined;
                                              }
                                            | undefined;
                                        }
                                      | undefined;
                                  }
                                | undefined;
                            }
                          | {
                              __typename: 'ComponentSlicesText';
                              id: string;
                              ComponentSlicesText_content?: string | undefined;
                              ComponentSlicesText_mode?:
                                | Types.Enum_Componentslicestext_Mode
                                | undefined;
                            }
                          | {
                              __typename: 'ComponentSlicesVerticalTab';
                              id: string;
                              title?: string | undefined;
                              content?: string | undefined;
                              image?:
                                | {
                                    __typename: 'UploadFileEntityResponse';
                                    data?:
                                      | {
                                          __typename: 'UploadFileEntity';
                                          id: string;
                                          attributes?:
                                            | {
                                                __typename: 'UploadFile';
                                                url?: string | undefined;
                                              }
                                            | undefined;
                                        }
                                      | undefined;
                                  }
                                | undefined;
                            }
                          | { __typename: 'Error' }
                          | undefined
                        >
                      | undefined;
                    seo?:
                      | {
                          __typename: 'ComponentSharedSeo';
                          id: string;
                          keywords?: string | undefined;
                          metaDescription?: string | undefined;
                          metaRobots?: string | undefined;
                          metaTitle?: string | undefined;
                          metaImage?:
                            | {
                                __typename: 'UploadFileEntityResponse';
                                data?:
                                  | {
                                      __typename: 'UploadFileEntity';
                                      id: string;
                                      attributes?:
                                        | {
                                            __typename: 'UploadFile';
                                            url?: string | undefined;
                                          }
                                        | undefined;
                                    }
                                  | undefined;
                              }
                            | undefined;
                        }
                      | undefined;
                  }
                | undefined;
            }
          | undefined;
      }
    | undefined;
};

export const GetChHomePageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetChHomePage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'publicationState' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PublicationState' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'I18NLocaleCode' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pagesChHome' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'publicationState' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'publicationState' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locale' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'locale' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subtitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ctaLabel' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locale' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'body' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'AdvertisingSlice',
                                    },
                                  },
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'FaqSlice' },
                                  },
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'FeaturesListSlice',
                                    },
                                  },
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'ImageSlice' },
                                  },
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'ImageWithTextSlice',
                                    },
                                  },
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'LinkListSlice',
                                    },
                                  },
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'NewsletterSlice',
                                    },
                                  },
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'SectionSlice',
                                    },
                                  },
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'SportListSlice',
                                    },
                                  },
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'StoreGradeSlice',
                                    },
                                  },
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'TabEndSlice',
                                    },
                                  },
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'TestimonialSlice',
                                    },
                                  },
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'TextSlice' },
                                  },
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'VerticalListSlice',
                                    },
                                  },
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'FeaturesListSlice',
                                    },
                                  },
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'LivesSliderSlice',
                                    },
                                  },
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'TabStartSlice',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'seo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'Seo' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StrapiImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UploadFileEntityResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'alternativeText' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'height' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdvertisingSlice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComponentSlicesAdvertising' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: {
              kind: 'Name',
              value: 'ComponentSlicesAdvertising_advertising_id',
            },
            name: { kind: 'Name', value: 'advertising_id' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FaqSlice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComponentSlicesFaq' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeaturesListSlice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComponentSlicesFeaturesList' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: {
              kind: 'Name',
              value: 'ComponentSlicesFeaturesList_features',
            },
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'icon' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attributes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageSlice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComponentSlicesImage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'ComponentSlicesImage_image' },
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StrapiImage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageWithTextSlice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComponentSlicesImageWithText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'ComponentSlicesImageWithText_text' },
            name: { kind: 'Name', value: 'text' },
          },
          {
            kind: 'Field',
            alias: {
              kind: 'Name',
              value: 'ComponentSlicesImageWithText_image',
            },
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StrapiImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: {
              kind: 'Name',
              value: 'ComponentSlicesImageWithText_imagePosition',
            },
            name: { kind: 'Name', value: 'imagePosition' },
          },
          {
            kind: 'Field',
            alias: {
              kind: 'Name',
              value: 'ComponentSlicesImageWithText_imageSize',
            },
            name: { kind: 'Name', value: 'imageSize' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LinkListSlice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComponentSlicesLinkList' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'ComponentSlicesLinkList_liens' },
            name: { kind: 'Name', value: 'liens' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attributes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'ComponentSlicesLinkList_color' },
            name: { kind: 'Name', value: 'color' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewsletterSlice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComponentSlicesNewsletter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'ComponentSlicesNewsletter_title' },
            name: { kind: 'Name', value: 'title' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'ComponentSlicesNewsletter_image' },
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: {
              kind: 'Name',
              value: 'ComponentSlicesNewsletter_buttonText',
            },
            name: { kind: 'Name', value: 'buttonText' },
          },
          {
            kind: 'Field',
            alias: {
              kind: 'Name',
              value: 'ComponentSlicesNewsletter_signedText',
            },
            name: { kind: 'Name', value: 'signedText' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SectionSlice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComponentSlicesSection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'ComponentSlicesSection_theme' },
            name: { kind: 'Name', value: 'theme' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SportListSlice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComponentSlicesSportList' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sports' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreGradeSlice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComponentSlicesStoreGrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'androidDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'androidLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'androidNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iosDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iosLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iosNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaLabel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TabEndSlice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComponentSlicesTabEnd' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TestimonialSlice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComponentSlicesTestimonial' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'ComponentSlicesTestimonial_name' },
            name: { kind: 'Name', value: 'name' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'ComponentSlicesTestimonial_role' },
            name: { kind: 'Name', value: 'role' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'ComponentSlicesTestimonial_review' },
            name: { kind: 'Name', value: 'review' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'ComponentSlicesTestimonial_image' },
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StrapiImage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextSlice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComponentSlicesText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'ComponentSlicesText_content' },
            name: { kind: 'Name', value: 'content' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'ComponentSlicesText_mode' },
            name: { kind: 'Name', value: 'mode' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VerticalListSlice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComponentSlicesVerticalTab' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LivesSliderSlice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComponentSlicesLivesSlider' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'ComponentSlicesLivesSlider_limit' },
            name: { kind: 'Name', value: 'limit' },
          },
          {
            kind: 'Field',
            alias: {
              kind: 'Name',
              value: 'ComponentSlicesLivesSlider_sportSlug',
            },
            name: { kind: 'Name', value: 'sportSlug' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TabStartSlice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComponentSlicesTabStart' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'ComponentSlicesTabStart_title' },
            name: { kind: 'Name', value: 'title' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Seo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComponentSharedSeo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metaDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metaImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'metaRobots' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metaTitle' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetChHomePageQuery, GetChHomePageQueryVariables>;
