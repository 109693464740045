import { css } from '@emotion/react';
import { Typography, Button } from '@mui/material';
import { Stack } from '@mui/system';
import { Markdown, Shape, ShapeLayout, Wrapper } from '@scorenco/components';
import { RouteLink, Types } from '@scorenco/core';

import { RouteKey } from '../../../routes';

type HomeHeroProps = {
  title: string;
  subtitle?: string;
  ctaLabel?: string;
  images?: Types.UploadFileRelationResponseCollection;
};

export const HomeHero = ({
  title,
  subtitle,
  ctaLabel,
  images,
}: HomeHeroProps) => {
  return (
    <Wrapper>
      <Stack
        gap={2}
        justifyContent="space-around"
        alignItems="center"
        py={{ xs: 4, sm: 2 }}
        direction={{ xs: 'column', sm: 'row' }}
      >
        <Stack
          py={{ xs: 0, sm: 8, lg: 16 }}
          alignItems="start"
          gap={2}
          maxWidth="sm"
        >
          <Typography
            component="h1"
            variant="h1"
            css={(theme) => css`
              strong {
                color: ${theme.vars.palette.secondary.main};
              }
            `}
          >
            <Markdown isInline value={title} />
          </Typography>
          {subtitle && (
            <Typography component="p" variant="subtitle1">
              <Markdown isInline value={subtitle} />
            </Typography>
          )}
          {ctaLabel && (
            <RouteLink
              href={{ routeKey: RouteKey.Connect, params: { state: 'signup' } }}
            >
              <Button component="a" variant="contained" color="secondary">
                {ctaLabel}
              </Button>
            </RouteLink>
          )}
        </Stack>

        <Stack
          css={css`
            width: 30vw;
            min-width: 15rem;
            max-width: 30rem;
            z-index: 0;
            justify-content: center;
            align-items: center;

            @keyframes wait {
              0% {
                opacity: 0;
              }
              100% {
                opacity: 0;
              }
            }

            @keyframes moveUp {
              0% {
                opacity: 0;
                transform: translate(-100px, 100px);
              }
              100% {
                opacity: 1;
                transform: translate(0, 0);
              }
            }

            @keyframes moveDown {
              0% {
                opacity: 0;
                transform: translate(100px, -100px);
              }
              100% {
                opacity: 1;
                transform: translate(0, 0);
              }
            }
          `}
        >
          <ShapeLayout
            css={css`
              width: 100%;
            `}
          >
            <Shape
              src={images?.data?.[0]?.attributes?.url}
              sx={{ animation: 'wait 0.2s, moveUp 0.5s ease-in-out 0.2s' }}
            />
            <Shape
              src={images?.data?.[1]?.attributes?.url}
              sx={{ animation: 'wait 0.4s, moveUp 0.5s ease-in-out 0.4s' }}
            />
            <Shape
              src={images?.data?.[2]?.attributes?.url}
              sx={{ animation: 'wait 0.6s, moveUp 0.5s ease-in-out 0.6s' }}
            />
            <Shape
              src={images?.data?.[3]?.attributes?.url}
              sx={{ animation: 'wait 0.8s, moveUp 0.5s ease-in-out 0.8s' }}
            />
          </ShapeLayout>
        </Stack>
      </Stack>
    </Wrapper>
  );
};
